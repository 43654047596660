import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Reviews For Nikkael Home Loans | Tustin Mortgage Broker"
        description="Thousands of happy clients have already secured their perfect mortgage! Read some of the reviews for Nikkael Home Loans here and join them in your dream home."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative overflow-hidden py-16 md:py-24">
        <div className="absolute left-0 top-0 h-full w-full">
          <StaticImage
            src="../images/Reviews/Hero Desktop.jpg"
            loading="eager"
            layout="fullWidth"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[800px] text-center">
            <h1 className="text-white">So Many Happy Clients!</h1>
            <p className="mb-8 text-white/80">
              Join those who are already living in their dream home thanks to
              the top mortgage lender in Orange County—your team at Nikkael.
            </p>

            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </div>
        </div>
      </section>

      <section className="mb-32 pt-16 md:mb-32 md:pt-20">
        <div className="container">
          <div className="md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
